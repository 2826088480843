
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import { ElNotification } from "element-plus";
import { useReCaptcha } from "vue-recaptcha-v3";

export default defineComponent({
  name: "reset-password",
  components: {
    Field,
    Form,
    ErrorMessage
  },
  setup() {
    const router = useRouter();
    const submitButton = ref<HTMLElement | null>(null);
    const submitButtonTwo = ref<HTMLElement | null>(null);
    const active = ref(0);
    const copyRight = process.env.VUE_APP_COPY_RIGTH;

    const { executeRecaptcha, recaptchaLoaded }: any = useReCaptcha();

    const forgotPassword = Yup.object().shape({
      username: Yup.string().required("Хэрэглэгчийн нэр хоосон байна."),
      email: Yup.string()
        .email("Цахим шуудан буруу байна.")
        .required("Цахим шуудан хоосон байна.")
    });

    const forgotPasswordStep2 = Yup.object().shape({
      username: Yup.string().required("Хэрэглэгчийн нэр хоосон байна."),
      email: Yup.string()
        .email("Цахим шуудан буруу байна.")
        .required("Цахим шуудан хоосон байна."),
      verifycode: Yup.string().required("Код хоосон байна.")
    });

    function handleSteps() {
      active.value++;
    }

    const onSubmitForgotPasswordStepOne = async values => {
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      await recaptchaLoaded()
        .then(async () => {
          await executeRecaptcha("login")
            .then(token => {
              const recaptchaToken = { recaptcha: token };
              const payload = Object.assign(values, recaptchaToken);
              store
                .dispatch(Actions.FORGOT_PASSWORD, payload)
                .then(
                  response => {
                    if (response.code === 0) {
                      ElNotification({
                        title: "Алдаа",
                        message: response.error,
                        type: "error",
                        iconClass: "el-text-error"
                      });
                    } else if (response.code === 1) {
                      Swal.fire({
                        text:
                          values.email + " цахим шуудан руу код илгээгдлээ.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Дараагийн алхам",
                        customClass: {
                          confirmButton: "btn fw-bold btn-light-primary"
                        }
                      }).then(function() {
                        handleSteps();
                      });
                    }
                  },
                  () => {
                    Swal.fire({
                      text: "Алдаа гарлаа.",
                      icon: "warning",
                      buttonsStyling: false,
                      confirmButtonText: "Дахин оролдоно уу!",
                      customClass: {
                        confirmButton: "btn fw-bold btn-light-warning"
                      }
                    });
                  }
                )
                .finally(() => {
                  submitButton.value?.removeAttribute("data-kt-indicator");
                });
            })
            .catch(() => {
              Swal.fire({
                text: "Баталгаажуулалт амжилтгүй.",
                icon: "warning",
                buttonsStyling: false,
                confirmButtonText: "Дахин оролдоно уу!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-warning"
                }
              });
            });
        })
        .catch(() => {
          Swal.fire({
            text: "Баталгаажуулалт амжилтгүй.",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Дахин оролдоно уу!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-warning"
            }
          });
        });
    };

    const onSubmitForgotPasswordStepTwo = async values => {
      submitButtonTwo.value?.setAttribute("data-kt-indicator", "on");
      await recaptchaLoaded()
        .then(async () => {
          await executeRecaptcha("login")
            .then(token => {
              const recaptchaToken = { recaptcha: token };
              const payload = Object.assign(values, recaptchaToken);
              store
                .dispatch(Actions.FORGOT_PASSWORD_STEP_TWO, payload)
                .then(
                  response => {
                    if (response.code === 0) {
                      ElNotification({
                        title: "Алдаа",
                        message: response.error,
                        type: "error",
                        iconClass: "el-text-error"
                      });
                    } else if (response.code === 1) {
                      Swal.fire({
                        text:
                          values.email +
                          " цахим шуудан руу шинэ нууц үг илгээгдлээ.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Нүүр хуудас",
                        customClass: {
                          confirmButton: "btn fw-bold btn-light-primary"
                        }
                      }).then(function() {
                        router.push({ name: "login" });
                      });
                    }
                  },
                  () => {
                    Swal.fire({
                      text: "Алдаа гарлаа.",
                      icon: "warning",
                      buttonsStyling: false,
                      confirmButtonText: "Дахин оролдоно уу!",
                      customClass: {
                        confirmButton: "btn fw-bold btn-light-warning"
                      }
                    });
                  }
                )
                .finally(() => {
                  submitButtonTwo.value?.removeAttribute("data-kt-indicator");
                });
            })
            .catch(() => {
              Swal.fire({
                text: "Баталгаажуулалт амжилтгүй.",
                icon: "warning",
                buttonsStyling: false,
                confirmButtonText: "Дахин оролдоно уу!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-warning"
                }
              });
            });
        })
        .catch(() => {
          Swal.fire({
            text: "Баталгаажуулалт амжилтгүй.",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Дахин оролдоно уу!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-warning"
            }
          });
        });
    };

    return {
      onSubmitForgotPasswordStepOne,
      onSubmitForgotPasswordStepTwo,
      forgotPassword,
      active,
      forgotPasswordStep2,
      submitButton,
      submitButtonTwo,
      copyRight
    };
  }
});
